import React, {
  useState,
  FunctionComponent,
  useEffect,
  Fragment,
  useContext,
  useCallback,
  SyntheticEvent,
  useRef,
  ChangeEvent,
  useMemo,
} from "react"
import _ from "lodash"
import Grid from "@material-ui/core/Grid"
import { FormControlLabel, Switch, Typography } from "@material-ui/core/"
import { WertstoffAnalysisTable } from "../WertstoffAnalysisTable"
import { useTranslation } from "react-i18next"
import { Button, Container, makeStyles, Theme } from "@material-ui/core"
import { InvalidityType, LabelSetMaterialType } from "../../../../api/graphql/generated/globalTypes"
import { toast } from "react-toastify"
import { SectionLoadingIndicator } from "../../dashboard/SectionLoadingIndicator"
import { AuthContext } from "../../../../context/AuthContext"
import { WertstoffMappedClassificationDropdown } from "../../controls/WertstoffMappedClassificationDropdown"
import { WertstoffAnalysisDetailMetadata } from "./WertstoffAnalysisDetailMetadata"
import { WerstoffAnalysisDetailDeleteButton } from "./WerstoffAnalysisDetailDeleteButton"
import { Skeleton } from "@material-ui/lab"
import { WertstoffAnalysisInvalidateAnalysisCheckbox } from "./WerstoffAnalysisInvalidateAnalysisCheckbox"
import { WertstoffDetailContext } from "./WertstoffDetailContext"
import { UpdateMaterialAnalysisVariables } from "../../../../api/graphql/mutation/update-material-analysis"

//most Images that are shown appear to be approx. 534px in height
const MIN_HEIGHT = 534

// @Styles
const useStyles = makeStyles((theme: Theme) => ({
  bagSection: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2),
  },
  overlaySwitch: {
    position: "absolute",
    top: theme.spacing(1),
    color: theme.palette.primary.main,
    width: 120,
  },
  track: {
    backgroundColor: "#808080",
  },
  switchBase: {
    color: theme.palette.primary.main,
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  subHeading: {
    fontSize: 14,
  },
  buttonLeft: {
    paddingRight: theme.spacing(0.5),
  },
  buttonRight: {
    paddingLeft: theme.spacing(0.5),
  },
  hidden: {
    display: "none",
  },
  skeletonContainer: {
    width: "100%",
    height: "100%",
  },
  skeletonRoot: {
    "&:after": {
      background: `linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1) , transparent)`,
    },
  },
  loadingIndicatorContainer: {
    padding: theme.spacing(2),
    minHeight: MIN_HEIGHT,
  },
  imageBackground: {
    backgroundColor: theme.palette.text.primary,
    minHeight: MIN_HEIGHT,
  },
}))

interface IWertstoffAnalysisDetailProps {}

export const WertstoffAnalysisDetail: FunctionComponent<IWertstoffAnalysisDetailProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { isAreaManager, isAdmin, isFractionImageAbility } = useContext(AuthContext)
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const currentContainerRef = useRef<HTMLDivElement>(null)

  const {
    analysis,
    mappedClassification,
    evaluation,
    updateMaterialAnalysisMutation,
    useBagSeparation,
    loading,
    onAnalysisDeleted,
    setMappedClassification,
    shouldInvalidateAnalysis,
    invalidReason,
  } = useContext(WertstoffDetailContext)

  const { trashImage, overlayImage, hasTrashAndOverlay } = useMemo(() => {
    let documents = _.get(analysis, "documents")
    let trashImage: string | undefined = undefined
    let overlayImage: string | undefined = undefined
    if (!_.isEmpty(documents)) {
      trashImage = _.get(_.find(documents, ["documentType", "trash_image"]), "url")
      overlayImage = _.get(_.find(documents, ["documentType", "overlay_image"]), "url")
    }
    return {
      trashImage,
      overlayImage,
      hasTrashAndOverlay: !!trashImage && !!overlayImage,
    }
  }, [evaluation, analysis])

  const [showOverlay, setShowOverlay] = useState(false)
  const [showFractionImage, setShowFractionImage] = useState(false)
  const [pictureUrl, setPictureUrl] = useState(trashImage)

  const invalidType: InvalidityType | undefined = useMemo(
    () => (analysis?.invalidity.isInvalid ? analysis.invalidity.invalidType : undefined),
    [analysis],
  )
  const invalidText = useMemo(() => {
    let _invalidText = analysis?.invalidity.invalidReason
    if (analysis?.invalidity.invalidType === InvalidityType.GENERAL) {
      _invalidText = t(`material_analysis.invalidity_type.${analysis.invalidity.invalidType}`, {
        reason: analysis.invalidity.invalidReason?.split("0;")[1] || "",
      })
    } else if (analysis?.invalidity.invalidType === InvalidityType.BY_USER) {
      _invalidText = t(`material_analysis.invalidity_type.${analysis?.invalidity.invalidType}`, {
        reason: analysis.invalidity.invalidReason,
      })
    } else if (analysis?.invalidity.invalidType !== InvalidityType.OTHER) {
      _invalidText = t(`material_analysis.invalidity_type.${analysis?.invalidity.invalidType}`)
    }

    return _invalidText
  }, [analysis])

  useEffect(() => {
    setPictureUrl(_.defaultTo(showOverlay ? overlayImage : trashImage, ""))
  }, [showOverlay, overlayImage, trashImage])

  const labelSetMaterialsForUpperTable = useBagSeparation
    ? [LabelSetMaterialType.main_fraction, LabelSetMaterialType.ignore, LabelSetMaterialType.missthrow]
    : [
        LabelSetMaterialType.main_fraction,
        LabelSetMaterialType.ignore,
        LabelSetMaterialType.missthrow,
        LabelSetMaterialType.bags,
      ]

  const onAnalysisDeletionError = useCallback(() => {
    toast.error(t("analysis_page.analysis_could_not_be_deleted"))
  }, [])

  const updateMappedClassificationOfAnalysis = useCallback(() => {
    if (!analysis) {
      return
    }

    const variables: UpdateMaterialAnalysisVariables = {
      id: analysis.id,
      mappedClassification,
    }

    if (shouldInvalidateAnalysis) {
      variables.invalidity = {
        isInvalid: true,
        invalidReason,
        invalidType: InvalidityType.BY_USER,
      }
    }

    updateMaterialAnalysisMutation({
      variables,
    })
  }, [updateMaterialAnalysisMutation, analysis, mappedClassification, shouldInvalidateAnalysis, invalidReason])

  const onImgLoad = useCallback((event: SyntheticEvent<HTMLImageElement>) => {
    setImageLoaded(true)
  }, [])

  const onOverlaySwitchChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setImageLoaded(false)
    setShowOverlay(event.target.checked)
  }, [])

  const onFractionImageSwitchChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setImageLoaded(false)
    setShowFractionImage(event.target.checked)
  }, [])

  const showImage = useMemo(() => {
    return isFractionImageAbility() && showFractionImage ? true : false
  }, [isFractionImageAbility, showFractionImage])

  return (
    <Fragment>
      {loading && (
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          className={classes.loadingIndicatorContainer}
        >
          <SectionLoadingIndicator />
        </Grid>
      )}
      {!loading && (
        <Grid container>
          {!!pictureUrl && (
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              xs={12}
              md={8}
              ref={currentContainerRef}
              className={classes.imageBackground}
            >
              {isFractionImageAbility() && (
                <div style={{ color: "red" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showFractionImage}
                        onChange={onFractionImageSwitchChanged}
                        value="showFractionImage"
                        color="primary"
                        classes={{ track: classes.track, switchBase: classes.switchBase }}
                      />
                    }
                    label={"Normal"}
                  />

                  {showImage && (
                    <img
                      style={{ width: "100%", objectFit: "cover" }}
                      onLoad={onImgLoad}
                      src={pictureUrl}
                      alt={pictureUrl}
                      className={!imageLoaded ? classes.hidden : undefined}
                    />
                  )}
                </div>
              )}

              {!imageLoaded && (
                <Grid container className={classes.skeletonContainer}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height="100%"
                    animation="wave"
                    classes={{
                      root: classes.skeletonRoot,
                    }}
                  />
                </Grid>
              )}
              {hasTrashAndOverlay && (
                <FormControlLabel
                  className={classes.overlaySwitch}
                  disabled={!imageLoaded}
                  control={
                    <Switch
                      checked={showOverlay}
                      onChange={onOverlaySwitchChanged}
                      value="showOverlay"
                      color="primary"
                      classes={{ track: classes.track, switchBase: classes.switchBase }}
                    />
                  }
                  label={showOverlay ? t("analysis_detail.less") : t("analysis_detail.more")}
                />
              )}
            </Grid>
          )}
          <Grid container item xs={12} md={!!pictureUrl ? 4 : 12} className={classes.content}>
            <Grid container alignContent="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Typography variant={"h6"} className={classes.heading}>
                  {t("analysis_detail.image_information")}
                </Typography>
                {analysis && <WertstoffAnalysisDetailMetadata analysis={analysis} />}
              </Grid>

              {!!evaluation && (
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Typography variant={"h6"} className={classes.heading}>
                      {t("analysis_detail.title")}
                    </Typography>
                    <WertstoffAnalysisTable
                      evaluation={evaluation}
                      withBags={!useBagSeparation}
                      compact
                      labelSetMaterialTypes={labelSetMaterialsForUpperTable}
                    />
                  </Grid>

                  {useBagSeparation && (
                    <Grid item xs={12} className={classes.bagSection}>
                      <WertstoffAnalysisTable
                        evaluation={evaluation}
                        compact
                        withBags={true}
                        labelSetMaterialTypes={[LabelSetMaterialType.bags]}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              {!!invalidType && (
                <Grid item xs={12}>
                  <Grid container direction={"column"} justify={"flex-start"} alignItems={"flex-start"}>
                    <Grid item xs={12}>
                      <Typography variant={"h6"} className={classes.heading}>
                        {t("analysis_detail.invalid_image_title")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={"body2"}>{invalidText}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid container alignContent="flex-end" spacing={2}>
              {isAdmin() && !invalidType && (
                <Grid item xs={12}>
                  <WertstoffAnalysisInvalidateAnalysisCheckbox />
                </Grid>
              )}
              {isAdmin() && analysis && mappedClassification && (
                <Grid item xs={12}>
                  <WertstoffMappedClassificationDropdown
                    mappedClassification={mappedClassification}
                    automatic={analysis.metadata.automatic}
                    onMappedClassificationUpdated={setMappedClassification}
                  />
                </Grid>
              )}
              {isAreaManager() && analysis && (
                <Grid item xs={6} className={classes.buttonLeft}>
                  <Grid item>
                    <WerstoffAnalysisDetailDeleteButton
                      id={analysis.id}
                      onCompleted={onAnalysisDeleted}
                      onError={onAnalysisDeletionError}
                    />
                  </Grid>
                </Grid>
              )}

              {isAdmin() && (
                <Grid item xs={6} className={classes.buttonRight}>
                  <Button
                    onClick={updateMappedClassificationOfAnalysis}
                    disableElevation
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {t("general.save")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}
